<div class="home-content" *ngIf="brochureContent">
  <!--  This container-backgrounds is needed to be able to have overflow: hidden inside here and have position sticky for the menu that works-->
  <div class="container-backgrounds">
    <div class="image-mountain" [ngStyle]="{ transform: 'scale(' + scaleRatio + ')' }"></div>
    <div class="gradient-complicated"></div>
  </div>

  <a class="login-platform-button" [href]="programWebsiteUrl">
    <img [src]="PotImages.avatar" [alt]="'Login to platform' | translate" />
  </a>

  <div *ngIf="displayGetInTouchFloatingButton" class="get-in-touch-floating-button" (click)="goToGetInTouchSection()">
    <pot-icon-svg-envelope />
    <pot-icon-svg-chevron-down-full [fillColor]="'#ffffff'" />
  </div>

  <div class="header-home container-sm" #menuItemTitle>
    <pot-icon-potentialife></pot-icon-potentialife>
    <h1>{{ brochureContent.homePage.topPageText }}</h1>
    @if (brochureContent.homePage.topButtons) {
      <!--      <div class="grid-container">-->
      <!--        @for (buttonBlock of brochureContent.homePage.topButtons; track buttonBlock.id) {-->
      <!--          <pot-block-button class="grid-item" [id]="buttonBlock.id" [data]="buttonBlock.data" />-->
      <!--        }-->
      <!--      </div>-->

      <div class="tw-flex tw-flex-col tw-gap-4 sm:tw-flex-row [&_pot-button]:tw-w-full">
        @for (buttonBlock of brochureContent.homePage.topButtons; track buttonBlock.id) {
          <pot-block-button [id]="buttonBlock.id" [data]="buttonBlock.data" />
        }
      </div>
    } @else {
      <div class="tw-flex tw-gap-4">
        <pot-button (clicked)="openModalToWatchTedTalk()" [buttonStyle]="ButtonStyle.white">
          Watch our TED Talk
        </pot-button>
        <pot-button (clicked)="goToGetInTouchSection()" [buttonStyle]="ButtonStyle.white"> Get In Touch </pot-button>
      </div>
    }
    <div #belowGetInTouchButton></div>
  </div>

  <app-sticky-menu [menuItems]="menuItems" (menuItemIdClicked)="onMenuItemClicked($event)"></app-sticky-menu>

  <div class="">
    <div class="before-menu-section">
      <pot-block-manager [blocks]="brochureContent.homePage.beforeMenuBlocks" />
    </div>

    <div
      *ngFor="let menuSection of brochureContent.homePage.menuSections; let last = last"
      #menuItemTitle
      class="section"
      [class.lastSection]="last"
    >
      <h2
        class="text-align-center container-sm"
        [ngStyle]="{ color: menuSection.titleFontColor }"
        [attr.data-aos]="menuSection.aosAnimation?.type"
        [attr.data-aos-offset]="menuSection.aosAnimation?.offset"
        [attr.data-aos-delay]="menuSection.aosAnimation?.delay"
      >
        {{ menuSection.title }}
      </h2>

      <pot-block-manager [blocks]="menuSection.blocks" />
    </div>
  </div>
</div>
