<div class="cards-list-container">
  <pot-block-card-single
    *ngFor="let card of cards"
    [ngClass]="numberCardsPerRow"
    [class.cardsAlignedOnSameRow]="cardsAlignedOnSameRow"
    [aosAnimation]="card.aosAnimation"
    [buttonData]="card.button"
    [descriptionSmall]="card.descriptionSmall"
    [description]="card.description"
    [icon]="card.icon"
    [imgBackGroundUrl]="card.imgBackGroundUrl"
    [imgObjectFit]="card.imgObjectFit"
    [mediaType]="card.mediaType"
    [modalContent]="card.modal"
    [reduceWhiteOverlay]="card.reduceWhiteOverlay"
    [textAlign]="card.textAlign"
    [title]="card.title"
    [video]="card.video"
  ></pot-block-card-single>
</div>
