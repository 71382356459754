export enum CustomCssClassBlock {
  marginBottom10 = 'pot-margin-bottom-10',
  marginBottom20 = 'pot-margin-bottom-20',
  marginBottom30 = 'pot-margin-bottom-30',
  marginBottom40 = 'pot-margin-bottom-40',
  marginBottom50 = 'pot-margin-bottom-50',
  marginBottom60 = 'pot-margin-bottom-60',
  marginBottom100 = 'pot-margin-bottom-100',
  marginBottom160 = 'pot-margin-bottom-160',
  marginBottom200 = 'pot-margin-bottom-200',
  marginTop48Bottom24 = 'pot-margin-top-48-bottom-24',
  marginTop10 = 'pot-margin-top-10',
  marginTop20 = 'pot-margin-top-20',
  marginTop30 = 'pot-margin-top-30',
  marginTop40 = 'pot-margin-top-40',
  marginTop50 = 'pot-margin-top-50',
  marginTop60 = 'pot-margin-top-60',
  paddingBottom10 = 'pot-padding-bottom-10',
  paddingBottom20 = 'pot-padding-bottom-20',
  paddingBottom30 = 'pot-padding-bottom-30',
  paddingBottom40 = 'pot-padding-bottom-40',
  paddingBottom50 = 'pot-padding-bottom-50',
  paddingBottom60 = 'pot-padding-bottom-60',
  paddingTop10 = 'pot-padding-top-10',
  paddingTop20 = 'pot-padding-top-20',
  paddingTop30 = 'pot-padding-top-30',
  paddingTop40 = 'pot-padding-top-40',
  paddingTop50 = 'pot-padding-top-50',
  paddingTop60 = 'pot-padding-top-60',
  textAlignCenter = 'text-align-center',
}

export enum FontStyle {
  potH1 = 'pot-h1',
  potH2 = 'pot-h2',
  potH3 = 'pot-h3',
  potH4 = 'pot-h4',
  potSubtitle = 'pot-subtitle',
  potP = 'pot-p',
}

export enum HorizontalAlignment {
  start = 'start',
  center = 'center',
  end = 'end',
}

export enum PotImages {
  avatar = 'assets/avatar.svg',
  potentialifeLogo = 'assets/potentialife-logo-192x192.png',
}

export enum BlockType {
  button = 'button',
  cardSingle = 'cardSingle',
  cardsList = 'cardsList',
  cardsCarousel = 'cardsCarousel',
  image = 'image',
  imageGrid = 'imageGrid',
  lottie = 'lottie',
  paragraphs = 'paragraphs',
  paragraphsCarousel = 'paragraphsCarousel',
  contactUs = 'contactUs',
}

export enum AosAnimation {
  fadeUp = 'fade-up',
  fadeDown = 'fade-down',
  fadeLeft = 'fade-left',
  fadeRight = 'fade-right',
  flipDown = 'flip-down',
  flipLeft = 'flip-left',
  flipRight = 'flip-right',
  flipUp = 'flip-up',
}

export enum ObjectFitCssValue {
  contain = 'contain',
  cover = 'cover',
  fill = 'fill',
  none = 'none',
  scaleDown = 'scale-down',
}

export interface AosAnimationConfig {
  type?: AosAnimation;
  delay?: number; // default: 0 - values from 0 to 3000, with step 50ms
  offset?: number; // default: 0.4, // offset (in percentage of the screen's height) from the original trigger point
}

export enum NumCardsPerRow {
  oneMobileTwoDesktop = 'oneMobileTwoDesktop',
  twoMobileThreeDesktop = 'twoMobileThreeDesktop',
}

export interface BlockAbstract {
  id?: string;
  type: BlockType;
  data: any;
}

export interface ModalContent {
  title: string;
  blocks?: Block[];
}

export enum IconPosition {
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
}

export enum IconType {
  linkedin = 'linkedin',
}

export interface IconLink {
  iconPosition: IconPosition;
  iconType: IconType;
  iconLink: string;
}

export interface Caption {
  label: string;
  lang: string;
  src: string;
}

export interface VideoData {
  poster: string;
  source_hd: string;
  source_sd: string;
}

export enum MediaType {
  image = 'image',
  video = 'video',
}

export interface BlockCardDataCommon {
  aosAnimation?: AosAnimationConfig | undefined;
  description: string;
  descriptionSmall?: string | undefined;
  icon?: IconLink | undefined;
  id?: string | undefined;
  imgBackGroundUrl: PotImages | string | null;
  imgObjectFit: ObjectFitCssValue;
  mediaType: MediaType;
  reduceWhiteOverlay?: boolean | undefined;
  textAlign?: HorizontalAlignment | undefined;
  title: string;
  type: BlockType.cardSingle;
  video: VideoData | null;
}

export interface BlockCardDataSimple extends BlockCardDataCommon {
  button?: never;
  modal?: never;
}

export interface BlockCardDataWithModal extends BlockCardDataCommon {
  button?: never;
  modal: ModalContent;
}

export interface BlockCardDataWithButton extends BlockCardDataCommon {
  button: BlockButtonData;
  modal?: never;
}

export type BlockCardData = BlockCardDataSimple | BlockCardDataWithModal | BlockCardDataWithButton;

export interface BlockCardsList extends BlockAbstract {
  type: BlockType.cardsList;
  numberCardsPerRow?: NumCardsPerRow;
  cardsAlignedOnSameRow?: boolean;
  data: BlockCardData[];
}

export interface BlockCardsCarousel extends BlockAbstract {
  type: BlockType.cardsCarousel;
  data: BlockCardData[];
  aosAnimation?: AosAnimationConfig;
}

export interface BlockContactUsData {
  aosAnimation?: AosAnimationConfig;
  hideMeetTheTeamLink?: boolean;
  title: string;
  buttonLabel: string;
}

export interface BlockContactUs extends BlockAbstract {
  type: BlockType.contactUs;
  data: BlockContactUsData;
}

export interface BlockParagraphData {
  alignTextOnLeft?: boolean;
  aosAnimation?: AosAnimationConfig;
  customCssClass?: CustomCssClassBlock[];
  fontColor?: string;
  fontStyle?: FontStyle;
  hasQuotesIcons?: boolean;
  paragraphs: string[];
}

export interface BlockParagraph extends BlockAbstract {
  type: BlockType.paragraphs;
  data: BlockParagraphData;
}

export interface BlockParagraphCarouselData {
  addQuotesSignInCorners?: boolean;
  customCssClass?: CustomCssClassBlock[];
  backgroundColorContainer?: string;
  paragraphsInfo: BlockParagraphData;
}

export interface BlockParagraphCarousel extends BlockAbstract {
  type: BlockType.paragraphsCarousel;
  data: BlockParagraphCarouselData;
}

export interface BlockImageData {
  alignImage?: HorizontalAlignment;
  alt?: string;
  aosAnimation?: AosAnimationConfig;
  backgroundColorContainer?: string;
  customCssClass?: CustomCssClassBlock[];
  imgBorderRadius?: string;
  maxHeight?: string;
  maxWidth?: string;
  src: string;
  stickersHTML?: string[];
}

export interface BlockImage extends BlockAbstract {
  type: BlockType.image;
  data: BlockImageData;
}

export interface BlockLottieData {
  aosAnimation?: AosAnimationConfig;
  customCssClass?: CustomCssClassBlock[];
  alignImage?: HorizontalAlignment;
  jsonUrl?: string;
  jsonUrls: string[];
  width?: string;
  height?: string;
  loop?: number | boolean;
}

export interface BlockLottie extends BlockAbstract {
  type: BlockType.lottie;
  data: BlockLottieData;
}

export function isBlockImage(value: BlockImage | BlockImageData): value is BlockImage {
  return value instanceof Object && value.constructor === Object && (value as BlockImage).type === BlockType.image;
}

export enum ImageGridStyle {
  autoCarousel = 'autoCarousel',
  carousel = 'carousel',
  default = 'default',
  oneRandomImage = 'oneRandomImage',
}

export interface AutoCarouselConfig {
  leftToRight: boolean;
  speedInMs: number;
  spaceBetweenImagesInPx: number;
}

export interface BlockImageDataGrid {
  images: BlockImageData[];
  height?: string;
  width?: string;
  aosAnimation?: AosAnimationConfig;
  gridStyle?: ImageGridStyle;
  autoCarouselConfig?: AutoCarouselConfig;
  customCssClass?: CustomCssClassBlock[];
}

export interface BlockImageGrid extends BlockAbstract {
  type: BlockType.imageGrid;
  data: BlockImageDataGrid;
}

export enum ButtonStyle {
  lightOrange = 'lightOrange',
  white = 'white',
}

export enum ButtonType {
  externalLink = 'externalLink',
  internalLink = 'internalLink',
  openModalToRegisterEmailAndSendDocument = 'openModalToRegisterEmailAndSendDocument',
  openModalWithYoutubeVideo = 'openModalWithYoutubeVideo',
}

export interface BlockButtonDataAbstract {
  align: HorizontalAlignment;
  aosAnimation?: AosAnimationConfig;
  buttonStyle?: ButtonStyle;
  customCssClass?: CustomCssClassBlock[];
  href: string;
  label: string;
  type: ButtonType;
}

export interface BlockButtonDataInternalLink extends BlockButtonDataAbstract {
  type: ButtonType.internalLink;
}

export interface BlockButtonDataExternalLink extends BlockButtonDataAbstract {
  type: ButtonType.externalLink;
}

export interface BlockButtonEmailData {
  modal: {
    title: string;
    optionalTopText: string;
    sendEmailButtonLabel: string;
    confirmationMessage: string;
  };
  email: {
    subject: string;
    beforeButtonMarkdown: string;
    downloadDocumentButtonLabel: string;
    afterButtonMarkdown: string;
  };
}

export interface BlockButtonDataOpenModalToSendEmail extends BlockButtonDataAbstract {
  type: ButtonType.openModalToRegisterEmailAndSendDocument;
  emailData: BlockButtonEmailData;
}

export interface BlockButtonDataOpenModalYoutube extends BlockButtonDataAbstract {
  type: ButtonType.openModalWithYoutubeVideo;
}

export type BlockButtonData =
  | BlockButtonDataExternalLink
  | BlockButtonDataInternalLink
  | BlockButtonDataOpenModalYoutube
  | BlockButtonDataOpenModalToSendEmail;

export interface BlockButton extends BlockAbstract {
  type: BlockType.button;
  data: BlockButtonData;
}

export function isBlockButtonDataOpenModalToSendEmail(
  value:
    | BlockButtonDataExternalLink
    | BlockButtonDataInternalLink
    | BlockButtonDataOpenModalYoutube
    | BlockButtonDataOpenModalToSendEmail
    | null,
): value is BlockButtonDataOpenModalToSendEmail {
  return (
    !!value &&
    value.constructor === Object &&
    value.type === ButtonType.openModalToRegisterEmailAndSendDocument &&
    !!value.emailData
  );
}

export function isBlockButton(value: BlockButton | BlockButtonData): value is BlockButton {
  return value.constructor === Object && (value as BlockButton).type === BlockType.button;
}

export type Block =
  | BlockButton
  | BlockCardsList
  | BlockCardsCarousel
  | BlockParagraphCarousel
  | BlockParagraph
  | BlockLottie
  | BlockImage
  | BlockImageGrid
  | BlockContactUs;

export interface MenuSection {
  aosAnimation?: AosAnimationConfig;
  id: string;
  titleForMenu: string;
  title: string;
  titleFontColor?: string;
  isGetInTouchSection?: boolean;
  blocks: Block[];
}

export interface FooterMenuItems {
  title: string;
  link: string;
}

export interface HomePageContent {
  topPageText: string;
  topButtons?: BlockButton[];
  beforeMenuBlocks: Block[];
  menuSections: MenuSection[];
}

export interface MeetTheTeamContent {
  topPageText: string;
  blocks: Block[];
}

export enum BrochureContentVersion {
  v1 = 'v1',
}

export interface BrochureContent {
  version: BrochureContentVersion;
  footerMenuItems: FooterMenuItems[];
  homePage: HomePageContent;
  meetTheTeam: MeetTheTeamContent;
}

export enum TargetBrochureContent {
  prod = 'prod',
  test = 'test',
}

export interface BrochureContentAPIPayload {
  brochureContent: BrochureContent;
  target?: TargetBrochureContent;
}
